import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type AbFtQueryVariables = Types.Exact<{ [key: string]: never; }>;


type AbFtQueryPayload = { __typename?: 'Query', abTests: { __typename?: 'AbTestsPayload', data: { __typename?: 'AbTestsData', abTests: Array<{ __typename?: 'AbTest', name: string, value: string, mixpanelProperty: string, enabled: boolean }> } | null }, features: { __typename?: 'FeatureTogglePayload', data: { __typename?: 'FeatureToggleList', featureToggles: Array<{ __typename?: 'FeatureToggle', name: string, enabled: boolean }> } } };


 const AbFtDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AbFt"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AbFt"}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AbFt"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Query"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"abTests"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"abTests"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"mixpanelProperty"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"features"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"featureToggles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}}]}}]}}]}}]} as unknown as DocumentNode<AbFtQueryPayload, AbFtQueryVariables>;


export type AbFtVariables = AbFtQueryVariables;
export type AbFtPayload = AbFtQueryPayload;

// remove fragments duplicates
// @ts-expect-error
AbFtDocument['definitions'] = [ ...new Set(AbFtDocument.definitions) ];

export default AbFtDocument;