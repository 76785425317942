import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type UserEmailUnsubscribeMutationVariables = Types.Exact<{
  input: Types.UserEmailInput;
}>;


type UserEmailUnsubscribeMutationPayload = { __typename?: 'Mutation', userEmailUnsubscribe: { __typename?: 'UserEmailUnsubscribePayload', error: { __typename?: 'ServerError', message: string, serverErrorCode: Types.ServerErrorCode } | { __typename?: 'ValidationError', message: string, validationErrorCode: Types.ValidationErrorCode } | null } };


 const UserEmailUnsubscribeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UserEmailUnsubscribe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserEmailInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userEmailUnsubscribe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ValidationError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"validationErrorCode"},"name":{"kind":"Name","value":"errorCode"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ServerError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"serverErrorCode"},"name":{"kind":"Name","value":"errorCode"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UserEmailUnsubscribeMutationPayload, UserEmailUnsubscribeMutationVariables>;


export type UserEmailUnsubscribeVariables = UserEmailUnsubscribeMutationVariables;
export type UserEmailUnsubscribePayload = UserEmailUnsubscribeMutationPayload;

// remove fragments duplicates
// @ts-expect-error
UserEmailUnsubscribeDocument['definitions'] = [ ...new Set(UserEmailUnsubscribeDocument.definitions) ];

export default UserEmailUnsubscribeDocument;