import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type UserLogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


type UserLogoutMutationPayload = { __typename?: 'Mutation', userLogout: { __typename?: 'UserLogoutPayload', error: { __typename?: 'SecurityError', errorCode: Types.SecurityErrorCode, message: string } | null } };


 const UserLogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UserLogout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userLogout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SecurityError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorCode"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UserLogoutMutationPayload, UserLogoutMutationVariables>;


export type UserLogoutVariables = UserLogoutMutationVariables;
export type UserLogoutPayload = UserLogoutMutationPayload;

// remove fragments duplicates
// @ts-expect-error
UserLogoutDocument['definitions'] = [ ...new Set(UserLogoutDocument.definitions) ];

export default UserLogoutDocument;